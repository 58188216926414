















































































    import {Component, Vue,Prop, Emit} from 'vue-property-decorator';
    import ChainService  from "@/services/ChainService"
    import { IChain } from '@/interfaces/MTChainModel';
    import Snackbar from "@/components/common/Snackbar.vue";
    import Navbar from '@/components/common/Navbar.vue';
    import router from '@/router/routes';
    @Component({
        components:{
            Snackbar,
            Navbar,
        }
    })

export default class Create extends Vue {
    @Prop() chainName!:IChain;
    @Prop() readonly asView!:boolean;
    @Prop()  asEdit!:boolean;
    private blank:boolean=true

    private snackBarMessage: string = "";
    private snackbar: boolean = false;
    private snackColor: string = "red";
    
    public Name:string="";
    private chainList:Array<IChain>=[];

    public submitChain(){
        if(this.asEdit){
            ChainService.UpdateChain(this.chainName.Id,this.chainName.name.trim())
            .then((res:any)=>{
                this.snackColor = "green";
                this.snackBarMessage = "Chain Updated successfully.!!";
                this.snackbar = true;
                setTimeout(() => this.$router.go(0),2000);  
                return

            })
            .catch((err:any)=>{
                console.log(err);
            })

        }
        else{
            ChainService.CreateChain(this.Name.trim())
            .then((res:any)=>{
                this.snackColor = "green";
                this.snackBarMessage = "Chain Created successfully.!!";
                this.snackbar = true;
                setTimeout(() => this.$router.push('/Chain/Manage'),2000);  
                return
            })
            .catch((err:any)=>{
                console.log(err);
            })
        }
    }
    closeSnackbar(value: boolean) {
        this.snackbar = value;
    }
    public isCopy:boolean=false;
    public dummy:string='';
    public ValidateForm() {
        this.dummy=this.Name.trim();
       this.chainList.forEach((e) => {
            // this.sdata.push(e.name);
            if(e.name==this.dummy)
            {
                this.isCopy=true;
  
            }
        });
    
        if (this.Name.trim() == '') {
            this.snackColor = "red";
            this.snackBarMessage = "Chain Name is Required!!";
            this.snackbar = true;
            
            return false;
        }
        else if(this.isCopy){
            this.snackColor = "red";
            this.snackBarMessage = "Chain Name Already Exist!!";
            this.snackbar = true; 
            this.isCopy=false;
            return false; 

        }
        else 
        {
            return true
        }
        
    }
    @Emit("submitUpdate")
    submitUpdate(){
        return
    }
    
    submit() {
        var valid = this.ValidateForm();
        if (valid) {
        this.submitChain();
        }
    }
    navigate(){
        if(this.asView||this.asEdit){
            this.$router.go(0)
        }
        else{
            router.push('/chain/manage')
        }
        
    }
    public async getChainList(status:boolean):Promise<boolean>{
        let that=this;
        return new Promise<boolean>(res=>{
            ChainService.getChainList(status)
            .then((response:any)=>{
                that.chainList=[];
                response.data.forEach((element:any, index:number)=>{
                    var item:IChain={
                        name:"",
                        Id:0
                    };
                    item.name=element.name,
                    item.Id=element.id,
                    that.chainList.push(item),
                    res(true);
                });
            })
            .catch((err:any)=>{
                console.log(err);
            })
        })
    }
    mounted(){
        this.getChainList(true)
        if(this.asView||this.asEdit)
        {
            this.Name=this.chainName.name;
            
        }
    }

}
