































































    import {Component, Vue} from 'vue-property-decorator';
    import Navbar from '@/components/common/Navbar.vue';
    import {ILink, ITableHeader } from '@/interfaces/common';
    import {IChain} from '@/interfaces/MTChainModel';
    import ChainService  from "@/services/ChainService"
    import DataTable from '@/components/common/Tables/DataTable.vue';
    import ActionablePopup from '@/components/common/Popup/ActionablePopup.vue';
    import Snackbar from '@/components/common/Snackbar.vue'
    import Create from '@/components/ModernTrade/ChainModule/Create.vue'
    import router from '@/router/routes';

    @Component({
        components:{
            Navbar,
            DataTable,
            ActionablePopup, 
            Snackbar,
            Create
        }
    })
export default class ManageChainMT extends Vue {
    private totalChains:number=0;
    public active:boolean=true;
    public inactiveChains:boolean=false;
    private tableHeaders:Array<ITableHeader>=[
        {
            text:'Chain Name',
            value:'name',
            sortable: true,
            default: 'N/A'
        },
        {
            text:'Actions',
            value:'action',
            sortable: false,
            default: ''
        }
    ]
    private inactiveTableHeaders:Array<ITableHeader>=[
        {
            text:'Chain Name',
            value:'name',
            sortable: true,
            default: 'N/A'
        },
        {
            text:'Actions',
            value:'action',
            sortable: false,
            default: ''
        }

    ]
    private menuActivate: Array<ILink>=[
        {
            Text: "View Details",
            Color: "primaryFABlue"
        },
        {
            Text: "Edit",
            Color: "primaryFABlue"
        },
        {
            Text: "Deactivate",
            Color: "red"
        }
    ];
    private menudeActivate: Array<ILink>=[
        {
            Text: "Activate",
            Color: "primaryFABlue"
        }
    ];
    private confirmPopupTitle:string=""
    private confirmPopupSubtitle:string=""
    private confirmPopupText:string=""
    private cancelBtnText:string=""
    private confirmBtnText:string=""
    private confirmationPopup:boolean=false
    private snackbar:boolean=false
    private snackbarcolor: string='success'
    private message:string=''
    private chainList:Array<IChain>=[];
    private data:any
    private selectedId:number=0;
    private view:boolean=false;
    private edit:boolean=false;
    private selectedChain:any;

    public toView(id:number){
        this.view=true;
    }
    public toEdit(){
        this.edit=true;
    }

    navigateToCreate(){
        router.push('/chain/create')
    }
    public switchChains(){
      
            this.getChainList(!this.inactiveChains)
     
    }

    public menuActions(chains:IChain, index:number){
        this.selectedId=chains.Id;
        this.selectedChain=chains;
        switch(this.menuActivate[index].Text){
            case "Deactivate":
                this.confirmPopupTitle="Confirm Deactivation";
                this.confirmPopupSubtitle="Chain will be deactivated!.";
                this.confirmPopupText="Are you sure you want to deactivate this chain?";
                this.cancelBtnText="Cancel";
                this.confirmBtnText="Deactivate";
                this.confirmationPopup=true;
                break;
            case "View Details":
                this.toView(this.selectedId);
                break;
            case "Edit":
                this.toEdit();
                break
            // case "Activate":
            //     this.confirmPopupTitle="Confirm Activation";
            //     this.confirmPopupSubtitle="Chain will be Activated!.";
            //     this.confirmPopupText="You are Activating '"+chains.name+"' chain.";
            //     this.cancelBtnText="Cancel";
            //     this.confirmBtnText="Deactivate";
            //     this.confirmationPopup=true;
            //     break;

        }
    }
   public menuActivateActions(chains:IChain, index:number){
        this.selectedId=chains.Id;
        this.selectedChain=chains;
        switch(this.menudeActivate[index].Text){
            case "Activate":
                this.confirmPopupTitle="Confirm Activation";
                this.confirmPopupSubtitle="Chain will be Activated!.";
                this.confirmPopupText="Are you sure you want to Activate this chain?";
                this.cancelBtnText="Cancel";
                this.confirmBtnText="Activate";
                this.confirmationPopup=true;
                break;

        }
    }
    public isCopy:boolean=false;
    public sdata:Array<string>=[];
    public tdata:Array<string>=[];
    submitUpdate(item:IChain){
        this.sdata.forEach((e) => {
            // this.sdata.push(e.name);
            if(e==item.name.trim())
            {
                this.isCopy=true;
  
            }
        });
        if(item.name.trim()==''||item.name==null){
            this.snackbarcolor = "red";
            this.message = "Chain Name is Mandatory!!";
            this.snackbar = true; 
            return false;
        }
        else if(this.isCopy){
            this.snackbarcolor = "red";
            this.message = "Chain Name Already Exist!!";
            this.snackbar = true; 
            this.isCopy=false;
            return false;   
        }
        else{
        ChainService.UpdateChain(item.Id,item.name.trim())
            .then((res:any)=>{
                this.snackbarcolor = "green";
                this.message = "Chain Updated successfully.!!";
                this.snackbar = true; 
                setTimeout(() => this.$router.go(0),2000); 
                return
                })
                .catch((err:any)=>{
                    console.log(err);
                })
        }
            
    }
    public actionablePopup(){
        this.snackbar = true;
        this.confirmationPopup = false;
    }
    public closeSnackbar(value: boolean): void{
        this.snackbar = value;
    }
    public confirmAction(value:any):void{
        if(this.inactiveChains==false){
            ChainService.DeactivateChain(value).then((res)=>{
                this.snackbarcolor="red";
                this.message="Chain Deactivated.";
                this.actionablePopup();
                this.switchChains();
            })
            .catch((err)=>{
                console.log(err);
            })
        }
        else if(this.inactiveChains==true){
         ChainService.ActivateChain(value).then((res)=>{
                this.snackbarcolor="green";
                this.message="Chain Activated";
                this.actionablePopup();
                this.switchChains();
            })
            .catch((err)=>{
                this.snackbarcolor="red";
                this.message="Chain name already exists";
                this.actionablePopup();
                this.switchChains();
                console.log(err);
            })
        }
    }
    public async getChainList(status:boolean):Promise<boolean>{
        let that=this;
        that.totalChains=0;
        return new Promise<boolean>(res=>{
            ChainService.getChainList(status)
            .then((response:any)=>{
                that.chainList=[];
                that.data=response.data;
                response.data.forEach((element:any, index:number)=>{
                    var item:IChain={
                        name:"",
                        Id:0
                    };
                    item.name=element.name,
                    item.Id=element.id,
                    that.chainList.push(item),
                    that.sdata.push(item.name),
                    that.totalChains=that.totalChains+1;
                    res(true);
                });
            })
            .catch((err:any)=>{
                console.log(err);
            })
        })
    }
    mounted(){
        this.getChainList(true)
    }
}
